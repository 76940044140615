import { render, staticRenderFns } from "./FacebookPostMatchDetails.vue?vue&type=template&id=28346710&scoped=true&"
import script from "./FacebookPostMatchDetails.vue?vue&type=script&lang=js&"
export * from "./FacebookPostMatchDetails.vue?vue&type=script&lang=js&"
import style0 from "./FacebookPostMatchDetails.vue?vue&type=style&index=0&id=28346710&prod&lang=css&"
import style1 from "./FacebookPostMatchDetails.vue?vue&type=style&index=1&id=28346710&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28346710",
  null
  
)

export default component.exports